<template>
  <canvas ref="threeCanvas" class="three-canvas"></canvas>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {
  createBlurMaterial,
  createEnhancedBlurMaterial,
} from "../utils/shaderMaterials";

export default {
  name: "ThreeBackground",
  props: {
    showPhone: { type: Boolean, default: false },
    showDataChart: { type: Boolean, default: false },
  },
  setup(props) {
    const threeCanvas = ref(null);
    let scene, camera, renderer, animationId;
    let controls;
    let phoneModel = null;
    let phonePivot = new THREE.Object3D();
    let chartModel = null;
    let chartPivot = new THREE.Object3D();
    let objects = [];
    let spriteObjects = [];
    let observer = null;
    let isVisible = false;

    const startRendering = () => {
      if (!isVisible) return;
      animate();
    };

    const stopRendering = () => {
      cancelAnimationFrame(animationId);
    };

    const observeVisibility = () => {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              isVisible = true;
              startRendering();
            } else {
              isVisible = false;
              stopRendering();
            }
          });
        },
        { threshold: 0.1 }
      );
      if (threeCanvas.value) observer.observe(threeCanvas.value);
    };

    onMounted(() => {
      // Scene
      scene = new THREE.Scene();
      scene.fog = new THREE.Fog(0x000000, 15, 40);

      // Camera
      const fov = 75;
      const aspect =
        threeCanvas.value.clientWidth / threeCanvas.value.clientHeight;
      const near = 0.1;
      const far = 1000;
      camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
      camera.position.set(0, -0.8, 6);

      // Renderer
      renderer = new THREE.WebGLRenderer({
        canvas: threeCanvas.value,
        alpha: true,
        antialias: true,
      });
      renderer.setSize(
        threeCanvas.value.clientWidth,
        threeCanvas.value.clientHeight
      );
      renderer.setPixelRatio(window.devicePixelRatio || 1);

      // Ambient and Directional Lights
      // Adjusting green to a brighter lime, adding orange and pink tones
      const ambientLight1 = new THREE.AmbientLight(0xffffff, 0.2);
      const ambientLight2 = new THREE.AmbientLight(0xbfff00, 0.1); // brighter lime green
      scene.add(ambientLight1, ambientLight2);

      const mainLight = new THREE.DirectionalLight(0xffffff, 0.4);
      mainLight.position.set(10, 10, 10);
      scene.add(mainLight);

      const limeLight = new THREE.DirectionalLight(0xbfff00, 0.3); // brighter lime green from left
      limeLight.position.set(-5, 2, 0);
      scene.add(limeLight);

      const backLight = new THREE.DirectionalLight(0x99ffcc, 0.2);
      backLight.position.set(0, 0, -5);
      scene.add(backLight);

      // Add pink and orange directional lights to give varied hues
      const pinkLight = new THREE.DirectionalLight(0xffc0cb, 0.2); // soft pink
      pinkLight.position.set(5, -2, 5);
      scene.add(pinkLight);

      const orangeLight = new THREE.DirectionalLight(0xffa500, 0.2); // dry orange
      orangeLight.position.set(-3, 5, -2);
      scene.add(orangeLight);

      // Controls
      controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.05;
      controls.enableZoom = false;
      controls.enablePan = false;
      controls.autoRotate = false;

      // Pivots
      scene.add(phonePivot);
      scene.add(chartPivot);

      // Models
      loadPhoneModel();
      loadChartModel();
      addOrbitingObjects();
      loadSprites();

      // Events
      window.addEventListener("resize", onWindowResize);
      observeVisibility();
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", onWindowResize);
      cancelAnimationFrame(animationId);
      renderer.dispose();
      controls.dispose();
      if (observer && threeCanvas.value) observer.unobserve(threeCanvas.value);
    });

    const onWindowResize = () => {
      if (threeCanvas.value) {
        const width = threeCanvas.value.clientWidth;
        const height = threeCanvas.value.clientHeight;
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height);
      }
    };

    const loadPhoneModel = () => {
      const loader = new GLTFLoader();
      loader.load(
        "/models/iphone_16/scene.gltf",
        (gltf) => {
          phoneModel = gltf.scene;
          phoneModel.scale.set(23, 23, 23);
          const box = new THREE.Box3().setFromObject(phoneModel);
          const center = box.getCenter(new THREE.Vector3());
          phoneModel.position.sub(center);
          phoneModel.position.y += 1;
          phonePivot.add(phoneModel);
        },
        undefined,
        (error) => {
          console.error("Error loading phone model:", error);
        }
      );
    };

    const loadChartModel = () => {
      const loader = new GLTFLoader();
      loader.load(
        "/models/scene.glb",
        (gltf) => {
          chartModel = gltf.scene;
          chartModel.scale.set(2, 2, 2); // slightly smaller

          const box = new THREE.Box3().setFromObject(chartModel);
          const center = box.getCenter(new THREE.Vector3());
          chartModel.position.sub(center);
          chartModel.position.y += 0.8;
          chartPivot.add(chartModel);
        },
        undefined,
        (error) => {
          console.error("Error loading chart model:", error);
        }
      );
    };

    const loadSprites = () => {
      const textures = [
        { path: "/sprites/graphic_pen.png", scale: 0.9 },
        { path: "/sprites/Figma-logo.png", scale: 0.6 },
        // { path: "/sprites/pointer.png", scale: 0.7 },
      ];

      textures.forEach(({ path, scale }) => {
        const textureLoader = new THREE.TextureLoader();
        textureLoader.load(
          path,
          (texture) => {
            const spriteMaterial = new THREE.SpriteMaterial({ map: texture });
            const sprite = new THREE.Sprite(spriteMaterial);
            sprite.scale.set(scale, scale, scale);
            const pivot = new THREE.Object3D();
            pivot.rotation.set(
              Math.random() * Math.PI,
              Math.random() * Math.PI,
              Math.random() * Math.PI
            );
            sprite.userData.orbit = {
              radius: 3,
              speed: 0.002,
              angle: Math.random() * Math.PI * 2,
            };
            pivot.add(sprite);
            scene.add(pivot);
            spriteObjects.push({ sprite, pivot });
          },
          undefined,
          (error) => {
            console.error(`Error loading sprite ${path}: ${error}`);
          }
        );
      });
    };

    const addOrbitingObjects = () => {
      const createOrbitingObject = (
        geometry,
        material,
        radiusOffset,
        yOffset,
        spinSpeed
      ) => {
        const object = new THREE.Mesh(geometry, material);
        const pivot = new THREE.Object3D();
        pivot.rotation.set(
          Math.random() * Math.PI,
          Math.random() * Math.PI,
          Math.random() * Math.PI
        );
        object.userData.orbit = {
          radius: 2.5 + radiusOffset,
          speed: 0.002 + Math.random() * 0.004,
          angle: Math.random() * Math.PI * 2,
          yOffset: yOffset,
          spinSpeed: spinSpeed,
        };
        pivot.add(object);
        scene.add(pivot);
        objects.push({ object, pivot });
      };

      createOrbitingObject(
        new THREE.SphereGeometry(0.3, 32, 32),
        createEnhancedBlurMaterial(),
        2,
        0.5,
        0.01
      );

      createOrbitingObject(
        new THREE.ConeGeometry(0.5, 0.68, 3),
        createEnhancedBlurMaterial(),
        4,
        1.0,
        0.02
      );

      createOrbitingObject(
        new THREE.TorusGeometry(0.6, 0.05, 16, 100),
        createEnhancedBlurMaterial(),
        3.5,
        -0.5,
        0.015
      );
    };

    const animate = () => {
      if (!isVisible) return;
      animationId = requestAnimationFrame(animate);

      phonePivot.rotation.y += 0.01;
      chartPivot.rotation.y += 0.01;

      objects.forEach(({ object, pivot }) => {
        const orbit = object.userData.orbit;
        orbit.angle += orbit.speed;
        object.position.x = orbit.radius * Math.cos(orbit.angle);
        object.position.z = orbit.radius * Math.sin(orbit.angle);
        object.position.y = orbit.yOffset;
        pivot.rotation.y += 0.0005;
        object.rotation.y += orbit.spinSpeed;
      });

      spriteObjects.forEach(({ sprite, pivot }) => {
        const orbit = sprite.userData.orbit;
        orbit.angle += orbit.speed;
        sprite.position.x = orbit.radius * Math.cos(orbit.angle);
        sprite.position.z = orbit.radius * Math.sin(orbit.angle);
        pivot.rotation.y += 0.0005;
      });

      if (phoneModel) phoneModel.visible = props.showPhone;
      if (chartModel) chartModel.visible = props.showDataChart;

      controls.update();
      renderer.render(scene, camera);
    };

    return {
      threeCanvas,
    };
  },
};
</script>

<style scoped>
.three-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
}
</style>
