<template>
  <section class="landing-page section relative">
    <!-- Three.js Background -->
    <ThreeBackground :showPhone="showPhone" :showDataChart="showDataChart" />

    <!-- Centered Logo -->
    <div class="logoAndSignature column">
      <h1 id="logo">patio</h1>
      <h2 class="regularWeight signature">
        design from research to implementation
      </h2>
    </div>

    <div class="content regularWeight column doubleGap">
      <div class="padded">
        <p class="divMarginBottom">
          We're an agency specialised in designing services end-to-end.
        </p>
        <div class="buttonContainer row gap">
          <button
            :class="{ active: selectedServices.includes('product') }"
            @click="toggleService('product')"
          >
            I'm working on a product
          </button>
          <button
            :class="{ active: selectedServices.includes('service') }"
            @click="toggleService('service')"
          >
            I'm working on a service
          </button>
        </div>
      </div>
    </div>

    <!-- Designer Woman Image -->
    <img
      v-if="showPhone"
      src="/img/landingpage/designer_woman.png"
      class="designer-woman woman"
      :class="{ visible: showPhone }"
      alt="Designer Woman"
    />

    <!-- Meeting Woman Image -->
    <img
      v-if="showDataChart"
      src="/img/landingpage/meeting_woman.png"
      class="meeting-woman woman"
      :class="{ visible: showDataChart }"
      alt="Meeting Woman"
    />
  </section>
</template>

<script>
import ThreeBackground from "./ThreeBackground.vue";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "LandingPage",
  components: {
    ThreeBackground,
  },
  setup() {
    const store = useStore();

    const toggleService = (service) => {
      // When a service is selected, remove the other from the selection
      if (service === "product") {
        store.commit("removeService", "service");
      } else if (service === "service") {
        store.commit("removeService", "product");
      }

      store.commit("toggleService", service);
    };

    const selectedServices = computed(() => store.state.selectedServices);

    const showPhone = computed(() =>
      selectedServices.value.includes("product")
    );
    const showDataChart = computed(() =>
      selectedServices.value.includes("service")
    );

    return {
      toggleService,
      selectedServices,
      showPhone,
      showDataChart,
    };
  },
};
</script>

<style scoped>
.landing-page {
  box-sizing: border-box;
  height: 100%;
  scroll-snap-align: start;
  position: relative;
  background: linear-gradient(
    to top,
    rgba(255, 165, 0, 0) 0%,
    rgba(255, 165, 0, 0) 9%,
    rgba(255, 165, 0, 0.45) 15%,
    rgba(255, 165, 0, 0.05) 65%,
    rgba(255, 165, 0, 0) 95%
  );
}

.logoAndSignature {
  padding: 0 var(--doubleSpace);
}

.content {
  bottom: 0;
  position: absolute;
  background-color: var(--bg-main-dark);
  z-index: 9;
  border-top: 1px solid var(--border-light);
  width: 100%;
}

#logo {
  font-family: "Pencerio-Hairline", system-ui, serif;
  font-size: 4rem;
  color: var(--text-white);
}

.signature {
  font-size: var(--largerFontSize);
  color: var(--text-white);
}

button.active {
  background-color: var(--light-pink);
  color: var(--bg-main-dark);
}

.designer-woman,
.meeting-woman {
  bottom: calc(70px + var(--smallClickableHeight));
  position: absolute;
  pointer-events: none;
  height: 50vh;
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
/* .woman {
  filter: grayscale(50%) sepia(50%) hue-rotate(265deg);
} */
.designer-woman.visible {
  transform: translateX(0px);
  opacity: 1;
}

.meeting-woman {
  right: 0px;
  transform: translateX(100%);
  z-index: 6;
  filter: grayscale(50%) sepia(50%) hue-rotate(265deg);
}

.meeting-woman.visible {
  transform: translateX(-10px);
  opacity: 1;
}

.designer-woman {
  z-index: 7;
  filter: grayscale(30%) sepia(40%) hue-rotate(265deg);
}

.padded {
  padding: var(--doubleSpace) var(--space);
}

button.primaryButton.active {
  background-color: var(--bg-light-white);
  color: var(--bg-main-dark);
}

@media (max-width: 768px) {
  #logo {
    font-size: 3.35rem;
  }
  .content {
    bottom: var(--smallClickableHeight) !important;
  }
  .designer-woman,
  .meeting-woman {
    bottom: calc(200px + var(--smallClickableHeight));
    height: 32vh;
  }
}

@media (max-width: 480px) {
  #logo {
    font-size: 3.35rem;
  }
}
</style>
