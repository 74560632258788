<template>
  <section class="partners-page relative">
    <div class="content column divDoubleMarginBottom headerPadded">
      <!-- <h2 class="gray">Meet the team</h2> -->
      <p class="gray">
        Diferent challenges require different expertise. That's why Patio's team
        is a diverse group of specialists working across disciplines to deliver
        refined, human-centred solutions.
      </p>
    </div>

    <!-- Partners Grid -->
    <div class="partners-grid">
      <div
        v-for="(partner, index) in partners"
        :key="partner.name"
        class="partner-card"
        ref="partnerRefs"
      >
        <!-- Existing partner card content -->
        <!-- Partner Header -->
        <div class="partner-header">
          <!-- Partner Photo -->
          <img
            v-if="partner.photo"
            :src="partner.photo"
            alt=""
            class="partner-photo responsive-img"
          />

          <!-- Partner Name and Info -->
          <div class="partner-info">
            <!-- Partner Name -->
            <h2 class="partner-name">{{ partner.name }}</h2>

            <!-- Expertise Chips -->
            <div
              v-if="partner.expertise && partner.expertise.length"
              class="chips-container"
            >
              <div
                class="chip"
                v-for="expertise in partner.expertise"
                :key="expertise"
              >
                {{ expertise }}
              </div>
            </div>
          </div>
        </div>

        <!-- Partner Bio -->
        <p class="partner-bio">{{ partner.bio }}</p>

        <!-- Media Carousel Component -->
        <MediaCarousel
          :workImages="partner.workImages"
          :videoLinks="partner.videoLinks"
        />
        <!-- Partner Logos -->
        <div
          v-if="partner.logos && partner.logos.length > 0"
          class="partner-logos"
        >
          <img
            v-for="logo in partner.logos"
            :key="logo"
            :src="logo"
            alt=""
            class="partner-logo"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted, reactive, provide, onBeforeUnmount } from "vue";
import MediaCarousel from "@/components/MediaCarousel.vue";
import LogoMarquee from "@/components/LogoMarquee.vue";
import config from "@/config.js";

export default {
  name: "PartnersPage",
  components: {
    MediaCarousel,
    LogoMarquee,
  },
  setup() {
    const currentPartnerIndex = ref(0);
    const partnerRefs = ref([]);

    const updateCurrentPartnerIndex = () => {
      partnerRefs.value.forEach((el, index) => {
        if (el) {
          const rect = el.getBoundingClientRect();
          if (rect.top >= 0 && rect.top < window.innerHeight) {
            currentPartnerIndex.value = index;
            console.log("Current partner index:", currentPartnerIndex.value);
          }
        }
      });
    };

    // Initialize partners
    const partners = reactive(
      config.partners.map((partner) => ({
        ...partner,
      }))
    );

    const allLogos = ref([]);

    onMounted(() => {
      // Flatten all partner logos into a single array, handling missing logos
      allLogos.value = partners.flatMap((partner) => partner.logos || []);
      window.addEventListener("scroll", updateCurrentPartnerIndex);
    });

    provide("currentPartnerIndex", currentPartnerIndex);
    provide("partnersLength", partners.length); // Ensure this line is here
    provide("partnerRefs", partnerRefs);

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", updateCurrentPartnerIndex);
    });

    return {
      partners,
      allLogos,
      updateCurrentPartnerIndex,
      partnerRefs,
    };
  },
};
</script>

<style scoped>
.partners-page {
  scroll-snap-align: start;
  position: relative;
  padding: var(--space) 0;
  min-height: 100vh;
}
.headerPadded {
  padding: var(--space);
  max-width: 80vw;
}

/* New grid container styles */
.partners-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
  gap: var(--space);
}

/* Adjusted partner card styles */
.partner-card {
  min-height: 100vh;
  scroll-snap-align: start;
  /* border: 1px solid var(--bg-light-lightMain); */
  border-radius: var(--border-radius-pill);
  background-color: transparent;
  display: flex;
  flex-direction: column;
}

/* Remove unnecessary margins and borders */
.partner-card:not(:last-child) {
  margin-bottom: 0;
  border-bottom: none;
}

/* Rest of your styles remain the same */
.partner-header {
  display: flex;
  align-items: center;
  padding: var(--space) var(--space) 0;
}

.partner-photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: var(--space);
  filter: grayscale(100%);
}

.partner-info {
  flex: 1;
}

.partner-name {
  font-size: var(--largerFontSize);
  font-weight: bold;
  margin-bottom: var(--smallSpace);
}

.chips-container {
  display: flex;
  flex-wrap: wrap;
  gap: var(--smallSpace);
}

.partner-bio {
  /* margin: var(--largeSpace) 0; */
  padding: 0 var(--space) var(--space) var(--space);
}

.partner-logos {
  display: flex;
  flex-wrap: wrap;
  gap: var(--largeSpace);
  padding: var(--space);

  /* margin-bottom: var(--largeSpace); */
}

.partner-logo {
  max-height: 38px;
  max-width: 95px;
  object-fit: contain;
  opacity: 0.3;
}

/* .partner-logo {
  max-height: 60px;
  max-width: 120px;
  object-fit: contain;
  opacity: 0.5;
} */

/* Adjust marquee logo sizes */

@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

/* Responsive Design */
@media (max-width: 600px) {
  .partners-grid {
    grid-template-columns: 1fr; /* Single column on mobile */
  }
  .partner-header {
    flex-direction: row;
    align-items: flex-start;
    gap: var(--space);
  }

  .partner-photo {
    margin-right: 0;
    margin-bottom: var(--space);
    max-width: 100px;
    height: 100px;
  }

  .partner-name {
    font-size: var(--largerFontSize);
  }

  .chip {
    font-size: 0.8rem;
  }
}
</style>
